import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import BlogList from '../components/blog-list'
import SiteMeta from '../components/meta-data'

class BlogIndex extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible:9,
    }

    this.loadMore = this.loadMore.bind(this)
  }

  loadMore(){
    this.setState((prev)=>{
      return {visible:prev.visible + 1}
    })
  }

  render() {
    const metaData = get(this.props, 'data.site.siteMetadata.blogPage')
    const servicesNav = get(this.props,'data.allContentfulOurServices.edges')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const tags  = Array.from(new Set(posts.reduce((acc, post) => acc.concat(post.node.tags),[])))
    const data = {
      hero:'hero-blog',
      size:'is-medium',
      isHidden: true,
      text: 'Blog',
      servicesNav
    }

    return (

      <Layout location={this.props.location} data={data} >
        <SiteMeta metaData={metaData}/>
        <BlogList posts={posts} tags={tags}/>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        blogPage {
          title
          description
          imageUrl
          siteUrl
        }
      }
    }  
    allContentfulBlogPost(filter: {node_locale: {eq: "en-GB"}} sort: {fields: [publishDate], order: DESC}) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            file {
              url
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulOurServices(filter: {node_locale: {eq: "en-GB"}}, sort: {order: ASC, fields: order}) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`
